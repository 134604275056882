import { Link } from "gatsby"
import React from "react"
import HorizontalMenu from "../sidebar/horizontalmenu"
import SideBarMenu from "../sidebar/sidebar"
import { StaticImage } from "gatsby-plugin-image"

const HeaderMain = ({ dataSrc }) => {
  return (
    <header style={{ backgroundColor: "#00989b" }}>
      <div id="nav-menu" className="menu-container sticky-on">
        <div className="container py-2">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="nav-brand">
                <Link
                  aria-label="link"
                  to="/"
                  className="d-block"
                  style={{ maxWidth: "200px" }}
                >
                  <StaticImage
                    className="w-100 h-auto mt-1 mt-md-0 nav-logo"
                    src="../../../static/assets/qcg-logo-color-2021.jpg"
                    alt="logo"
                    layout="constrained"
                    priority
                    // width={195}
                    // height={195}
                  />
                </Link>
              </div>
            </div>
            <div className="ml-auto">
              <div className="d-none d-lg-inline">
                <HorizontalMenu dataSrc={dataSrc} />
              </div>
              <div className="d-inline d-lg-none">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderMain
